.contact-us {
  position: relative;


  .contact-us-bg {
    position: absolute;
    background: rgba(215, 32, 126, 0.25);
    height: 550px;
    width: 100vw;
    right: 0;
    top: 125px;
    z-index: +5;
  }

  @media (max-width: 1199.5px) {
    .contact-us-bg {
      opacity: 0;
    }
  }

  .contact-us-head {
    position: relative;
    z-index: +500;

    .contact-us-img {
      filter: drop-shadow(0 6mm 4mm rgba(0, 0, 0, 0.76));

      img {
        width: 100%;
      }
    }

    @media (max-width: 1199.5px) {
      .contact-us-img {
        img {
          width: 50%;
          padding-bottom: 5%;
          margin: 0 auto;
          display: block;

        }
      }
    }

    .contact-row {
      display: flex;
      justify-content: space-between;
    }

    @media (max-width: 600px) {
      .contact-row {
        display: block;
        justify-content: unset;

        .contact-card {
          margin: 0 auto;
          display: block;
        }
      }
    }


    .contact-details-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      .contact-details-section-main {
        padding-left: 50px;
      }

      .contact-us-btn {
        color: #D7207E;
        font-weight: 500;
      }
    }

    @media (max-width: 1199.5px) {
      .contact-details-section {
        margin: 0 auto;
        width: 650px;
      }
    }

    @media (max-width: 767.5px) {
      .contact-details-section {
        margin: 0 auto;
        width: 600px;

        .contact-details-section-main {
          padding-left: 0px;
        }
      }
    }

    @media (max-width: 650px) {
      .contact-details-section {
        width: 500px;
        margin: 0 auto;
      }
    }

    .contact-description {
      color: #D7207E;
      font-size: 25px;
      font-style: italic;
      font-weight: 600;

    }

    @media (max-width: 550px) {
      .contact-description {
        font-size: 15px;
      }
    }

    .contact-details-main {
      .contact-detail-row {
        display: flex;
        justify-content: space-between;
        width: 300px;

        .contact-detail {
          width: 225px;
        }
      }
    }
  }


}