.section-title-head {
  position: relative;
  width: 90vw;
  display: flex;
  justify-content: center;
  margin: 0 auto;

  .section-title-main {
    position: absolute;
    font-size: 25px;
    font-weight: 900;
    padding-right: 1.5vw;
    background: #D7207E;
    color: #D7207E;
    overflow: hidden;
    text-align: center;

    .section-title {
      position: absolute;
      color: white;
      left: -1.5px;
      bottom: -12px;
      z-index: +500;
      text-align: center;
    }
  }

  .section-title-shadow {
    color: #D7207E;
    position: absolute;
    z-index: +5;
    font-size: 25px;
    font-weight: 900;
    top: 13.5px;
    left: -3px;
    padding-right: 1.5%;
    width: 90vw;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .section-title-head {
    right: 5%;
  }
}