.portfolio-section-one {


  .portfolio-section-one-items-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .portfolio-item {
      background: #2C176A;
      width: 275px;
      height: 275px;
      transition: 400ms;

      &:hover {
        transform: scale(1.1);
        transition: 400ms;
      }
    }
  }
}