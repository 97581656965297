.hero-section {
  min-height: 90vh;
  background: white;

  .hero-section-content {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .hero-section-bg-img {
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    .pleb-logo {
      left: 50%;
      position: absolute;
      margin-left: -175px;

      img {
        width: 350px;
        margin: 0 auto;
        display: block;
      }
    }

    .contact-us-section {
      position: absolute;
      bottom: 0;
      text-align: center;
      margin: 0 auto;
      display: block;
      width: 100%;

      .contact-us-section-description {
        color: black;
      }
    }
  }

  .pleb-studio-advantages {
    background: #2C176A;
    margin-top: 100px;

    .advantages-item-main {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
    }

    .advantage-item {
      background: #1E1E1E;
      width: 225px;
      height: 450px;
      position: relative;
      margin-right: 15px;
      margin-left: 15px;

      .pink-square {
        position: absolute;
        height: 40px;
        width: 40px;
        background: #D7207E;
        left: -20px;
        top: 20px
      }

      .advantage-item-title {
        position: absolute;
        font-weight: 600;
        font-size: 25px;
        top: 60px;
        left: -20px;
        width: 220px;
      }

      .advantage-item-content {
        position: absolute;
        height: 180px;
        width: 185px;
        top: 190px;
        left: 20px;

        .advantage-item-description {
          font-size: 15px;
          font-weight: 300;
        }
      }
    }
  }
}
