* {
  font-family: 'Poppins', sans-serif;

}

body {
  background: black;
  overflow-x: hidden;
}


.App {
  background: black;
  color: white;

  .page {
    margin-top: 10vh;
  }

  .btn {
    background: #ffffff;
    border: none;
    border-radius: 5px;
    height: 50px;
    width: 150px;
    color: black;

    &:hover {
      background: #ffffff;
      color: black;
    }
  }

  .btn.pink {
    background: #D7207E;
    border: none;
    border-radius: 5px;
    height: 50px;
    width: 150px;
    color: white;
  }

  .portfolio-description {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    display: block;
  }
}

.PhotoView-Portal{
  z-index: 2000000!important;
}
