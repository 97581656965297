.our-services {
  .service-items-main {

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}


