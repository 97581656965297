.testimonial-item {
  height: 300px;
  width: 500px;
  margin: 0 auto;
  display: block;
  background: black;
  border: 3px solid #D7207E;

  .profile-row {
    display: flex;
    justify-content: space-between;
  }

  .profile-info {
    display: flex;

    .profile-pic {
      height: 70px;
      width: 70px;
      background: #2C176A;
    }

    .profile-name {
      font-weight: 800;
      font-size: 18px;
    }

    .profile-tag {
      color: rgba(255, 255, 255, 0.5);
      font-weight: 300;
      font-size: 13px;
    }
  }

  .feedback {
    font-style: italic;
    height: 140px;
    overflow-y: scroll;
  }

  .feedback::-webkit-scrollbar {
    width: 0.5em;

  }

  .feedback::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #2C176A;
  }

  .feedback::-webkit-scrollbar-thumb {
    background-color: #2C176A;
  }

  .time {
    color: rgba(255, 255, 255, 0.5);
    font-size: 13px;
    margin-top: 5px;
  }
}

@media (max-width: 767.5px) {
  .testimonial-item {
    transform: scale(0.7);
  }
}