.footer {
  .footer-logo {
    margin: 0 auto;
    display: block;
    width: 100px;
  }

  @media (max-width: 600px) {
    .footer-logo {
      width: 100px;
    }
  }

  @media (max-width: 500px) {
    .footer-logo {
      width: 100px;
    }
  }

  .footer-description {
    text-align: center;
    width: 50%;
    margin: 0 auto;
    display: block;
    font-weight: 300;
  }

  .social-icons {
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin: 0 auto;
  }

  .foot-note {
    font-size: 14px;
    font-weight: 100;
  }
}

