.homepage {
  .portfolio-item{
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .get-in-touch-main {
    background: #D7207E;

    .get-in-touch {
      display: flex;
      justify-content: space-between;


      .get-in-touch-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        font-weight: 900;
        font-style: italic;
        font-size: 30px;
      }
    }
  }

  .thumbnail-designs {
    background-image: url("../../../../public/assets/portfolios/portfolio-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .infographic {
    .portfolio-section-one-items-main {
      .portfolio-item {
        box-shadow: -10px 10px #D7207E;
        transition: 400ms;

        &:hover {
          box-shadow: 0px 0px rgb(215, 32, 126);
          transition: 400ms;
        }
      }
    }
  }

  .ui-ux-design {
    background-image: url("../../../../public/assets/portfolios/portfolio-bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .blockchain-development {
    .portfolio-section-one-items-main {
      .portfolio-item {
        box-shadow: 10px 10px #D7207E;
        transition: 400ms;

        &:hover {
          box-shadow: 0px 0px rgb(215, 32, 126);
          transition: 400ms;
        }
      }
    }
  }

  .character-design {
    background-image: url("../../../../public/assets/portfolios/portfolio-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .NFT-design {
    .portfolio-section-one-items-main {
      .portfolio-item {
        box-shadow: -10px 10px #D7207E;
        transition: 400ms;

        &:hover {
          box-shadow: 0px 0px rgb(215, 32, 126);
          transition: 400ms;
        }
      }
    }
  }

}
