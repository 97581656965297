.NavBar {
  position: relative;
  z-index: +90000;

  .navbar-desktop-main {
    background: white;
    display: block;
    margin: 0 auto;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;

    .navbar-desktop {
      display: flex;
      justify-content: space-between;
      width: 60%;
      margin: 0 auto;
    }

    @media (max-width: 1100px) {
      .navbar-desktop {
        width: 75%;
      }
    }

    a {
      text-decoration: none;
      color: black;

      &:hover {

      }
    }
  }

  @media (max-width: 750px) {
    .navbar-desktop-main {
      display: none;
    }
  }

  .navbar-mobile-main {
    display: none;
  }

  @media (max-width: 750px) {
    .navbar-mobile-main {
      background: white;
      display: block;
      margin: 0 auto;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;

      .navbar-mobile {
        display: block;

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
        }

        .nav-link {
          color: black;
        }
      }
    }
  }
}

