.service-item {
  height: 300px;
  width: 300px;
  background: #000000;
  overflow: hidden;
  position: relative;
  z-index: +500;


  .service-item-bg-img {
    position: absolute;
    z-index: -50;

    img {
      width: 300px;
    }
  }

  .service-item-title {
    position: relative;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 20px;
    text-align: right;
    padding-top: 228px;
    transition: 400ms;
    z-index: +1000;
  }

  .service-item-content {
    position: absolute;
    top: 300px;
    height: 175px;
    background: #1E1E1E;
    font-size: 13px;
    font-weight: 200;
    transition: 400ms;

    .service-item-description {
      height: 125px;
    }

    .service-view {
      margin-top: 15px;
      text-align: center;

      a {
        text-decoration: none;
        color: white;
        font-weight: 500;

      }
    }
  }

  &:hover {
    .service-item-title {
      padding-top: 0px;
      transition: 400ms;
      color: #D7207E;
    }

    .service-item-content {
      top: 125px;
      transition: 400ms;
    }
  }
}