.testimonials {
  position: relative;

  .react-multiple-carousel__arrow {
    background: rgba(255, 255, 255, 0) !important;
  }

  .testimonial-row {
    position: relative;

    .carousel-main {
      position: relative;
      z-index: +500;
    }

    .testimonial-col {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: relative;
      z-index: +500;

      .testimonial-sub-title {
        font-weight: 800;
        font-size: 20px;
      }

      .buttons-row {
        display: flex;

        .btn {
          color: #D7207E;
          font-weight: 600;
        }
      }
    }

    @media (max-width: 1199.5px) {
      .testimonial-col {


        .testimonial-sub-title {
          text-align: center;
        }

        .testimonial-description {
          text-align: center;
        }

        .buttons-row {
          display: flex;
          margin: 0 auto;

          .btn {
            color: #D7207E;
            font-weight: 600;
          }
        }
      }
    }
  }

  .testimonial-bg {
    position: absolute;
    height: 360px;
    width: 72.5%;
    background: #2C176A;
    top: 160px;
    right: -0%;
    z-index: +20;
    opacity: 1;
  }

  @media (max-width: 1199.5px) {
    .testimonial-bg {
      position: absolute;
      height: 350px;
      width: 72.5%;
      background: #2C176A;
      top: 140px;
      right: -0%;
      z-index: +20;
      opacity: 0;
    }
  }


}

